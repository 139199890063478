import React from "react";
import { Header } from "../sections/Header";
import Footer from "../sections/Footer";
import { Team } from "../sections/Team";
import { Faqs } from "../sections/Faqs";
import { Features } from "../sections/Features";
import { Objectives } from "../sections/Objectives";
import { Testimonials } from "../sections/Testimonials";
import { TargetClients } from "../sections/TargetClients";
import { PatternStrip } from "../sections/PatternStrip";

export const Landing = () => {
  return (
    <>
      <Header />
      <Objectives />
      <TargetClients />
      <Features />
      <Team />
      <Testimonials />
      <PatternStrip />
      <Faqs />
      <Footer />
    </>
  );
};
