import { KeyboardArrowDown } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";

export const Dropdown = ({ label, list }) => {
  return (
    <div className="dropdown">
      <div className="flexCenter">
        {label} <KeyboardArrowDown fontSize="small" sx={{ ml: 0.5 }} />
      </div>

      <ul className="radius6 whiteBg dropdown_menu dropdown_menu--animated dropdown_menu-6">
        {list?.map((item, i) => (
          <li key={i}>
            <Link to={item?.to}>{item?.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
