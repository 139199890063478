import React from "react";

export const PatternStrip = () => {
  return (
    <div
      className="pattern2"
      style={{
        height: 132,
      }}
    />
  );
};
