import React from 'react'
import { HighlightSpan } from '../components/styled/Styled'
import styled from "styled-components";

export default function Whypos() {
  return (
    <Wrapper className="container flexSpaceCenter">

    <div>
        
      <Content>
      <h2 className="font30">
        <HighlightSpan>Why Choose Zimba’s Point of Sale?</HighlightSpan>
        <br></br>
        <br></br>
        </h2>
      <div
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="950"
            >
        <ul>
          <li><strong>Real-Time Transactions:</strong> Our POS devices are configured to work directly with Zimba’s core banking system, capturing transactions in real time and providing immediate updates. This ensures transparency and credibility, as all transactions are securely recorded and accessible</li>
          <li><strong>Field Collection:</strong>Loan and collection officers can now collect payments directly from the field, enhancing efficiency and reducing the need for borrowers to visit a branch. The POS system generates a receipt for each transaction, offering borrowers proof of payment and increasing institutional transparency.</li>
          <li><strong>Satellite Office Efficiency:</strong>These POS devices are perfect for satellite offices, enabling them to operate according to your institution’s policies without the costs associated with a fully-fledged office. This allows your organization to expand its reach and improve customer service without incurring significant overheads.</li>
        </ul>
        <br />
        <SpecBox>
            <h3>T1 Plus POS Specifications:</h3>
            <div className=''>
            <ul>
              <li>Operating System: Android 11</li>
              <li>Audio: Single speaker</li>
              <li>Ports: USB Type-C × 1</li>
              <li>Positioning: GPS / GLONASS / BEIDOU</li>
              <li>Indicator LEDs: Red / Blue / Yellow / Green</li>
              <li>Physical Keys: Power × 1 | Function × 1</li>
              <li>Cameras: 5 MP Rear | 2 MP Front</li>
              <li>Dimensions: 194.7 × 80 × 25.6mm, Weight: 428g</li>
              <li>Connectivity: WiFi, Bluetooth 4.2, Mobile Network: 4G LTE, 3G, 2G</li>
              <li>Power: 5000mAh Battery, 5V/2A Adapter</li>
              <li>Display: 5.5" HD IPS Touch Screen</li>
              <li>Card Readers: Chip & PIN, NFC, Magnetic Stripe</li>
              <li>Memory: 2GB RAM + 16GB Flash</li>
              <li>Processor: Quad-Core Cortex A53 1.5GHz</li>
              <li>Fingerprint Reader (Optional)</li>
              <li>Printer: 80mm/s print speed</li>
              <li>Environmental Tolerance: Operating temp: -10°C to 50°C</li>
              <li>Certifications: PCI 6, EMV, MasterCard TQM, Visa payWave</li>
            </ul>
            </div>
          </SpecBox>
        </div>
      </Content>
          </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const Content = styled.div`
  padding: 20px;
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  li {
    margin-bottom: 10px;
    font-size: 1rem;
  }
`;

const SpecBox = styled.div`
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    list-style: none;
    padding: 0;
    li {
      font-size: 1rem;
      line-height: 1.4;
    }
  }
  @media (max-width: 960px) {
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 560px) {
    ul {
      grid-template-columns: 1fr;
    }
    h3 {
      font-size: 1.2rem;
    }
    li {
      font-size: 0.9rem;
    }
  }
`;