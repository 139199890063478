import React from 'react';
import { HeaderTitle,} from '../components/styled/Styled';
import styled from "styled-components";
import {alpha } from "@mui/material";

export default function WalletFeatures() {
  return (
    <div className='combDark'>
    <Wrapper className="container flexSpaceCenter">
      <div className=""
      data-aos="fade-up"
      data-aos-delay="300"
      data-aos-duration="1500"
      
      >
          <h2 className="font30">
          <HeaderTitle>
            <span className="whiteColor">Key Features</span> 
          </HeaderTitle>
          </h2>
          <GridContainer>
            <GridBox>
              <Title>Sub-Wallets and Group Wallets</Title>
              <Description>Manage multiple wallets for different purposes or create group wallets for collective savings and shared expenses, offering enhanced flexibility and control.</Description>
            </GridBox>
            <GridBox>
              <Title>Account Statements</Title>
              <Description>Access detailed account statements within the wallet, providing clear visibility into all transactions and balances.</Description>
            </GridBox>
            <GridBox>
              <Title>Loan Applications</Title>
              <Description>Streamline the loan application process directly through the wallet, offering quick and easy access to financial support.</Description>
            </GridBox>
            <GridBox>
              <Title>Deposits and Withdrawals</Title>
              <Description>Perform deposits and withdrawals seamlessly according to your institution’s policies, allowing for efficient cash management.</Description>
            </GridBox>
            <GridBox>
              <Title>Multi-Currency and Cross-Border Capabilities</Title>
              <Description>The Zimba Wallet supports multiple currencies and cross-border transactions, enabling institutions and customers to transact beyond national borders with ease.</Description>
            </GridBox>
            <GridBox>
              <Title>Real-Time Transactions</Title>
              <Description>Enjoy instant updates on all wallet activities, enhancing transparency and keeping you informed at all times.</Description>
            </GridBox>
          </GridContainer>
      </div>
    </Wrapper>
    </div>
  )
}

const Wrapper = styled.section`
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;


const GridContainer = styled.div`
  max-width: 100;
  margin: 50px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GridBox = styled.div`
  background-color: ${alpha("#fff", 0.1)};
  padding: 10px 20px;
  line-height: 3rem;
  backdrop-filter: blur(10px);
  border: 1px solid ${alpha("#fff", 0.2)};
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease; /* add transition effect */
  width: 50

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* change box shadow on hover */
    transform: translateY(-5px); /* add a slight lift effect on hover */
  }
`;
const Title = styled.h3`
  color: #ffe7ca
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: #fff
  font-size: 0.813rem;
  margin-bottom: 20px;
  line-height: 1.8;
  font-weight: 300
`;