import React from 'react'
import Footer from "../sections/Footer";
import WalletFeatures from '../sections/WalletFeatures';
import { Wallet } from '../sections/wallet';
import Whywallet from '../sections/Whywallet';


export const Zimbawallet =() => {
  return (
    <>
    <Wallet/>
    <Whywallet />
    <WalletFeatures/>
    <Footer />
    </>
  )
}