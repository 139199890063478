import { Chip, Divider, IconButton, TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";
import {
  GapWrapper,
  HeaderTitle,
  HighlightSpan,
  TitleP,
} from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { socialLinks } from "../constants/links";
import { LogoDark } from "../assets/svg/Svgs";
import Footer from "./Footer";
import { Steps } from "antd";
import { LocalPhone, LocationOn, Mail } from "@mui/icons-material";
import { addressData } from "../constants/siteData";

export const Contact = () => {
  const [values, setValues] = React.useState({});
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <>
      <Wrapper className="poka-pattern flexCenter">
        <GridWrapper className="container">
          <LeftSide>
            <GapWrapper gap={20}>
              {/* <img src={Ills1} width="50%" alt="clients" /> */}
              <div>
                <Chip
                  label="Contact Us"
                  color="primary"
                  data-aos="zoom-in-up"
                  data-aos-duration="1000"
                />
              </div>
              <GapWrapper gap={10}>
                <HeaderTitle>
                  <HighlightSpan>Reach</HighlightSpan> Out To Us
                </HeaderTitle>
                <p
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                  className="semiBold"
                >
                  Sign Up with Zimba
                </p>
              </GapWrapper>

              <TitleP
                className="font14"
                data-aos="fade-up"
                data-aos-delay="500"
                data-aos-duration="1500"
              >
                To make requests for further information, contact us via this
                form. We will contact you as soon as we receive your form.
              </TitleP>

              <div
                style={{ paddingTop: 20 }}
                data-aos="fade-up"
                data-aos-delay="800"
                data-aos-duration="1500"
              >
                <Steps
                  direction="vertical"
                  current={3}
                  items={[
                    {
                      title: "Address",
                      description: addressData[0].text,

                      icon: <LocationOn />,
                    },
                    {
                      title: "Phone",
                      description: addressData[1].text,
                      icon: <LocalPhone />,
                    },
                    {
                      title: "Email",
                      description: addressData[2].text,
                      icon: <Mail />,
                    },
                  ]}
                />
              </div>
            </GapWrapper>
          </LeftSide>
          <BoxWrapper
            className="whiteBg radius8"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            <form>
              <GapWrapper gap={20} className="textCenter">
                <LogoDark height={50} />
                <InnerGrid>
                  <TextField
                    required
                    label="Name"
                    onChange={handleChange("name")}
                    fullWidth
                  />
                  <TextField
                    required
                    label="Phone"
                    fullWidth
                    onChange={handleChange("phone")}
                  />
                  <TextField
                    required
                    label="Email"
                    fullWidth
                    onChange={handleChange("email")}
                  />
                  <TextField
                    required
                    label="Organization"
                    fullWidth
                    onChange={handleChange("organization")}
                  />
                </InnerGrid>
                <TextField
                  required
                  label="Subject"
                  onChange={handleChange("subject")}
                  fullWidth
                />
                <TextField
                  required
                  label="Your Message"
                  placeholder="Describe to us what you need."
                  fullWidth
                  onChange={handleChange("message")}
                  multiline
                  rows={4}
                />
                <HeaderButton type="submit">Send Message</HeaderButton>

                <Divider />

                <div>
                  <p className="font12" style={{ marginBottom: 10 }}>
                    Reach Us On
                  </p>
                  {socialLinks.map(({ Icon, link }, i) => (
                    <IconButton key={i}>
                      <a
                        href={link}
                        className="animate mainColor flex lightLink"
                      >
                        <Icon />
                      </a>
                    </IconButton>
                  ))}
                </div>
              </GapWrapper>
            </form>
          </BoxWrapper>
        </GridWrapper>
      </Wrapper>
      <Footer />
    </>
  );
};

const GridWrapper = styled.div`
  padding-top: 50px;
  display: grid;
  height: 100%;
  align-items: center;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

const InnerGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.section`
  min-height: 100vh;
  padding: 80px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;

const BoxWrapper = styled.div`
  width: 100%;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.13) -60px 40px 80px;
  @media (max-width: 860px) {
    padding: 20px;
  }
`;

const LeftSide = styled.div`
  @media (max-width: 960px) {
    margin-top: 50px;
    text-align: center;
  }
`;
