import React from "react";
import TestimonialSlider from "../components/slider/TestimonialSlider";
import { Chip } from "@mui/material";
import {
  GapWrapper,
  HeaderTitle,
  HighlightSpan,
} from "../components/styled/Styled";
import styled from "styled-components";

export const Testimonials = () => {
  return (
    <>
      <Header>
        <GapWrapper gap={20} className="container">
          <div>
            <Chip
              data-aos-duration="1000"
              data-aos="zoom-in-up"
              label="Testimonials"
            />
          </div>
          <HeaderTitle>
            <p
              className="font30 extraBold gilroy"
              data-aos="fade-up"
              data-aos-duration="1500"
              
            >
              What do our <HighlightSpan>clients</HighlightSpan> have to say
            </p>
          </HeaderTitle>
        </GapWrapper>
      </Header>
      <TestimonialSlider />
    </>
  );
};

const Header = styled.div`
  padding: 70px 0 20px 0;
  @media (max-width: 960px) {
    width: 100%;
    text-align: center;
  }
`;
