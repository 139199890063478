import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import { testimonialData } from "../../constants/siteData";
import TestimonialBox from "../testimonial/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {testimonialData.map((val, i) => {
        return (
          <LogoWrapper
            data-aos="fade-right"
            data-aos-offset="400"
            data-aos-duration="1000"
            data-aos-delay={i * 100}
            className="flexCenter gilroy"
            key={i}
          >
            <TestimonialBox data={val} />
          </LogoWrapper>
        );
      })}
    </Slider>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5% 7% 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
