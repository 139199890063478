import React from "react";
import { HighlightSpan } from "../components/styled/Styled";
import styled from "styled-components";
import { objectivesData } from "../constants/siteData";
import { Chip } from "@mui/material";

import { Kccaimg, Pdpoimg, URSBimg, Fitspaimg, Nitaimg, Kbisimg, Umraimg } from "../assets/img/Images";

const CardItem = ({ data, index }) => {
  const { title, Icon, desc } = data;
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay={index === 1 ? 200 : 1000}
    className="gilroy">
      <CardWrapper
        index={index}
        className="flexCenter flexColumn textCenter radius8 animate"
      >
        <Icon style={{ height: 50, width: 50 }} />

        <h2 style={{ margin: "64px 0px 16px" }}>{title}</h2>
        <p className="font14" style={{ lineHeight: 1.8 }}>
          {desc}
        </p>
      </CardWrapper>
    </div>
  );
};

export const Objectives = () => {
  return (
    <Wrapper id="about">
      <div className="container">
        <div
          className="textCenter"
          style={{ paddingBottom: 5, lineHeight: 2 }}
        >
          <Chip
            data-aos-duration="1000"
            data-aos="zoom-in-up"
            color="primary"
            label={<p className="font12 gilroy">About Us</p>}
          />

          <div data-aos="fade-up" data-aos-duration="1500">
            <h2 className="font30">
              <HighlightSpan>Discover</HighlightSpan> Who We Are
            </h2>
          </div>
        </div>

        <GridWrapper>
          {objectivesData.map((val, i) => (
            <CardItem data={val} key={i} index={i} />
          ))}
        </GridWrapper>

        {/* New Section for Certificates */}
        <CertificateSection>
          <Heading data-aos="fade-up" data-aos-duration="1000">
            <HighlightSpan className="gilroy">Certifications</HighlightSpan>
          </Heading>
          <CertificateWrapper>
            {[
              { img: Kccaimg, alt: "kcca", height: 100 },
              { img: URSBimg, alt: "ursb", height: 50 },
              { img: Fitspaimg, alt: "fitspa", height: 50 },
              { img: Pdpoimg, alt: "data protection", height: 60 },
              { img: Nitaimg, alt: "nita", height: 60 },
              { img: Kbisimg, alt: "kla business summit", height: 70 },
              { img: Umraimg, alt: "umra", height: 30, },
            ].map((cert, i) => (
              <CertificateItem
                key={i}
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay={i * 200}
              >
                <img src={cert.img} alt={cert.alt} height={cert.height} />
              </CertificateItem>
            ))}
          </CertificateWrapper>
        </CertificateSection>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0 120px 0;
  @media (max-width: 960px) {
    padding: 70px 0;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 80px 50px;
  line-height: 3rem;
  height: 100%;
  box-shadow: ${(prop) =>
    prop.index === 1 && "rgba(145, 158, 171, 0.16) -40px 40px 80px"};
  :hover {
    box-shadow: rgba(145, 158, 171, 0.16) -40px 40px 80px;
    scale: 1.02;
  }
  @media (max-width: 860px) {
    padding: 50px 40px;
    box-shadow: rgba(145, 158, 171, 0.16) -40px 40px 80px;
  }
`;

const CertificateSection = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const Heading = styled.h3`
  font-size: 24px;
  margin-bottom: 30px;
`;

const CertificateWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const CertificateItem = styled.div`
  img {
    max-width: 100%;
    max-height: 100%;
    @media (max-width: 480px) {
      height: 50px;
    }
  }
  @media (max-width: 480px) {
    flex-basis: 100px; /* set minimum width for grid items on small screens */
  }
`;
