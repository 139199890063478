import React from "react";
import styled from "styled-components";

export const ServiceBox = ({ Icon, title, desc }) => {
  return (
    <Wrapper className="borderBox radius6 animate pointer">
      <div className="flexNullCenter gap10" style={{ marginBottom: 10 }}>
        <Icon className="highlightColor" />
        <p className="font15 semiBold">{title}</p>
      </div>

      <p className="font13 light">{desc}</p>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  line-height: 1.8;
  padding: 25px;
  height: 100%;
  @media (max-width: 860px) {
    padding: 20px;
  }
  :hover {
    scale: 1.04;
  }
`;
