import React from "react";
import styled from "styled-components";
import { HighlightSpan } from "../components/styled/Styled";
import {
  DashImg,
  LoansImg,
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Screen5,
  Screen6,
  Screen7,
} from "../assets/img/Images";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { scrollToId } from "../utils/Utils";

export const Header = () => {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      {/* Left */}
      <LeftSide className="flex flexColumn gap20">
        <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
          <h1 className="extraBold font60">
            Grow Faster with <HighlightSpan>Zimba</HighlightSpan>
          </h1>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
          <div className="">
            Automated <b>Solutions</b> for <b>Financial</b> Institutions
          </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <HeaderP className="font14">
            <p>
              Welcome to the <b>future</b> of financial management! In this day
              and age, relying on pen, paper, and Excel to handle loans and
              deposits is a thing of the past. Embrace the power of technology
              and let us introduce you to our <b>cutting-edge</b> custom
              core-banking software solutions.
            </p>
            <br />
            <p>
              Let us be your <b>trusted partner</b> on the journey towards
              success. Contact us today to learn more about how <b>Zimba</b> can
              transform your operations and empower your institution to thrive
              in the digital age.
            </p>
          </HeaderP>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" >
          <HeaderButton
            onClick={() => {
              scrollToId("about");
            }}
           >
            <p className="gilroy">Let's Explore</p>
          </HeaderButton>
        </div>
      </LeftSide>

      {/* Right */}
      <RightSide>
        <div
          className="poka-pattern"
          style={{
            height: "100%",
            width: "100%",
            left: 200,
            transform: "skew(-16deg, 4deg)",
            position: "absolute",
            zIndex: -1,
            borderRadius: "0 0 0 50px",
          }}
        />

        <div
          className=""
          style={{
            height: 200,
            bottom: 0,
            width: "100%",
            background: "linear-gradient(transparent, white)",
            position: "absolute",
            zIndex: 1,
            pointerEvents: "none",
          }}
        />

        <div
          className="flexCenter"
          style={{
            height: "100%",
          }}
        >
          <ScreensWrapper className="flex gap20">
            <div className="circles" style={{ width: 200 }}>
              <ImgWrapper src={Screen1} />
              <ImgWrapper src={Screen2} />
              <ImgWrapper src={Screen3} />
              <ImgWrapper src={Screen4} />
              <ImgWrapper src={Screen5} />

              <ImgWrapper src={Screen1} />
              <ImgWrapper src={Screen2} />
              <ImgWrapper src={Screen3} />
              <ImgWrapper src={Screen4} />
              <ImgWrapper src={Screen5} />
            </div>
            <div className="circles-reverse" style={{ width: 500 }}>
              <ImgWrapper src={DashImg} />
              <ImgWrapper src={Screen6} />
              <ImgWrapper src={LoansImg} />
              <ImgWrapper src={Screen7} />

              <ImgWrapper src={DashImg} />
              <ImgWrapper src={Screen6} />
              <ImgWrapper src={LoansImg} />
              <ImgWrapper src={Screen7} />
            </div>
          </ScreensWrapper>
        </div>
      </RightSide>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const ScreensWrapper = styled.div`
  width: 100%;
  margin-top: 200px;
  padding-left: 100px;
  transform: skew(-13deg, 4deg);
  @media (max-width: 960px) {
    margin: 0;
    transform: none;
    padding: 20px;
  }
`;

export const ImgWrapper = styled.img`
  transition: 0.3s ease all;
  border-radius: 0.8rem;
  margin-bottom: 2rem;
  width: 100%;
  box-shadow: 0 50px 50px -10px rgba(0, 0, 0, 0.2),
    20px 20px 15px rgba(0, 0, 0, 0.06);
  :hover {
    scale: 1.05;
  }
  @media (max-width: 960px) {
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-radius: 0 0 0 50px;

  @media (max-width: 960px) {
    border-radius: 20px;
    position: relative;
    max-height: 400px;
    width: 100%;
    order: 2;
    // display: none;
    // margin-top: 70px;
    // margin-bottom: 80px;
  }
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 20px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 20px 0;
    text-align: center;
    max-width: 100%;
  }
`;
