import React from "react";
import styled from "styled-components";

export const InfoSection = ({ data, order }) => {
  const { header, title, desc, Image } = data || {};
  return (
    <div className="poka-pattern">
      <div className="container gilroy">
        <Advertising className="flexSpaceCenter">
          <AddLeft>
            <div data-aos="fade-up" data-aos-duration="1500">
              <h4 className="font15 semiBold">{header}</h4>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="200"
              style={{ margin: "10px 0 30px 0" }}
            >
              <h2 className="font40 extraBold">{title}</h2>
            </div>

            <div className="font12">{desc}</div>
          </AddLeft>
          <AddRight>
            <AddRightInner>
              <div className="flexNullCenter">
                <AddImgWrapper
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  data-aos-delay="1000"
                >
                  {Image}
                </AddImgWrapper>
              </div>
            </AddRightInner>
          </AddRight>
        </Advertising>
      </div>
    </div>
  );
};

const Advertising = styled.div`
  padding: 100px 0;
  min-height: 80vh;
  position: relative;
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 100px 0;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapper = styled.div`
  width: 100%;
  margin: 50px;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 860px) {
    margin: 1rem 0 6rem 0;
  }
`;
