import { alpha } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { highlightColor, primaryColor } from "../styled/Theme";

export const TeamBox = ({ data }) => {
  const { image, title, name } = data;
  return (
    <Wrapper className="animate radius8 flex flexColumn gap10 whiteBg">
      <ImgWrapper className="radius8" src={image} />

      <h4>{name}</h4>
      <div className="flexCenter">
        <p
          className="radius8"
          style={{
            padding: "5px 15px",
            fontSize: 10,
            color: "white",
            border: `1px solid ${alpha(highlightColor, 0.2)}`,
            backgroundColor: primaryColor,
          }}
        >
          {title}
        </p>
      </div>

      {/* <Chip label={title} color="primary" sx={{ fontSize: 11 }} /> */}
      <div />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 10px 10px -20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 10px;
  :hover {
    scale: 1.03;
    box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.3),
      0 0 15px rgba(0, 0, 0, 0.06);
  }
`;

const ImgWrapper = styled.img`
  width: 100%;
  object-fit: cover;
  height: 200px;
  padding: 3px;
  @media (max-width: 420px) {
    height: 150px;
  }
`;
