import React from "react";
import { Landing } from "../pages/Landing";
import { Pricing } from "../pages/Pricing";
import { BankingPlatform } from "../pages/BankingPlatform";
import {
  contactLink,
  ezyfiLink,
  financialLiteracyLink,
  pointofsaleLink,
  pricingLink,
  privacyLink,
  ZimbawalletLink
} from "./links";
import { FinancialLiteracy } from "../pages/FinancialLiteracy";
import { Contact } from "../sections/Contact";
import { PrivacyPolicy } from "../sections/Privacy";
import { PointofSalem } from "../pages/PointofSalem";
import { Zimbawallet } from "../pages/Zimbawallet";

export const routes = [
  {
    path: "/",
    component: <Landing />,
  },
  {
    path: pricingLink,
    component: <Pricing />,
  },
  {
    path: contactLink,
    component: <Contact />,
  },
  {
    path: ezyfiLink,
    component: <BankingPlatform />,
  },
  {
    path: financialLiteracyLink,
    component: <FinancialLiteracy />,
  },
  {
    path: pointofsaleLink,
    component: <PointofSalem />,
  },
  {
    path: privacyLink,
    component: <PrivacyPolicy />,
  },
  {
    path: ZimbawalletLink,
    component: <Zimbawallet/>,
  },
];
