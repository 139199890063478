import React from 'react'
import { HighlightSpan } from '../components/styled/Styled'
import styled from "styled-components";

export default function Whywallet() {
  return (
    <Wrapper className="container flexSpaceCenter">

    <div>
        
      <Content>
      <h2 className="font30">
        <HighlightSpan>Why Choose the Zimba Wallet?</HighlightSpan>
        <br></br>
        <br></br>
        </h2>
      <div
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="950"
            >
        <ul>
          <li><strong>Seamless Loan Disbursements:</strong> Institutions can disburse loans directly to their customers' Zimba Wallets, making funds instantly accessible. Customers can easily convert loan amounts to mobile money
           or transfer them to any commercial bank account in Uganda, simplifying access to funds.</li>
          <li><strong>Flexible Transactions:</strong> The Zimba Wallet offers unparalleled flexibility, allowing users to make
           payments, deposits, and withdrawals directly into their savings accounts. 
           Customers can easily load money from their mobile money wallets to their Zimba Wallet, 
           enhancing convenience. Additionally, with integration into agency banking, 
           users can load money into their wallets at any agent banking location, making cash management effortless.</li>
          <li><strong>Comprehensive Bill Payments:</strong> Customers can pay for a variety of services, including Yaka, 
          National Water, airtime, school fees, and more, directly from the Zimba Wallet. 
          This all-in-one solution simplifies the management of everyday financial needs.</li>
          <li><strong>Regulated and Secure:</strong> Fully regulated by the Bank of Uganda through integration with ABC Bank,
           the Zimba Wallet adheres to the highest standards of security and compliance. 
           All deposits are insured by the National Deposit Protection Fund, 
          providing confidence and peace of mind for institutions and their customers.</li>
        </ul>
        <br />
        </div>
      </Content>
          </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const Content = styled.div`
  padding: 20px;
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  li {
    margin-bottom: 10px;
    font-size: 1rem;
  }
`;
