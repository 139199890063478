import React from 'react'
import { Pos } from '../sections/Pos'
import Footer from "../sections/Footer";
import Whypos from '../sections/Whypos';

export const PointofSalem =() => {
  return (
    <>
    <Pos />
    <Whypos />
    <Footer />
    </>
  )
}
