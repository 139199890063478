import React, { useState, useEffect  } from 'react';
import styled from "styled-components";
import {  HighlightSpan } from "../components/styled/Styled";
import PolicyIcon from '@mui/icons-material/Policy';
import DevicesIcon from '@mui/icons-material/Devices';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import SecurityIcon from '@mui/icons-material/Security';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CollectionsIcon from '@mui/icons-material/Collections';
import { ArrowUpSVG } from '../assets/svg/Svgs';


// export const PrivacyPolicy = () => {
//   const handleScrollToBoxes = () => {
//     document.getElementById("boxes-section").scrollIntoView({ behavior: "smooth" });
//   };

export const Privcy = () => {
    const [activeSection, setActiveSection] = useState(null);
    const [showBackToTop, setShowBackToTop] = useState(false); // State for showing the button

  
    const handleScrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      const titleHeight = 50; 
      const offset = section.offsetTop - titleHeight;
      window.scrollTo({ top: offset, behavior: "smooth" });
      setActiveSection(sectionId);
    };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

  };

  useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY > 60) {
            setShowBackToTop(true);
        } else {
            setShowBackToTop(false);
        }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  

  return (
    <div>
    <Wrapper className="container flexSpaceCenter">
    <InnerWrapper className='flex'>
      <BoxContainer id="boxes-section" className="flexSpaceBetween ">
        <Box id="box1">
          
          <div className='flex'><IconSection><PolicyIcon style ={{fontSize: '30px'}} /></IconSection><BoxTitle>Zimba Technologies Online Privacy Policy</BoxTitle></div>
          <hr style={{ border: '0.5px solid #ddd', margin:'24px 0px' }} />
          <BoxContent>
            <p>
            We at Zimba Technologies Incorporated (hereinafter mentioned as "Zimba") 
            are committed to protecting our customers’ 
            personal information and strive to maintain the privacy of your Personal Information.
            </p>
            <br></br>
            <p>
            “Personal information” is any information that can be used by itself to uniquely identify, contact, 
            or locate a person, or can be used with information available from other sources to uniquely identify an individual
            </p>
            <br></br>
            <p>
            Personal Information does not include information that is anonymous, aggregated or is no longer 
            identifiable to a natural person and such information is classified as Non-Personal Information
            </p>
            <br></br>
            <p>
            Personal information collected and/or held by us may include but is not limited to your name, father's name, mother's name, 
            spouse's name, date of birth, current and previous addresses, telephone number, mobile phone number, email address, occupation,
            and information contained in the documents or images used as proof of identity and proof of address
            </p>
            <br></br>
            <p>
            For the purpose of this policy, sensitive personal data or information like financial information
             (such as bank account or credit card or debit card or other payment instrument details), 
             passwords or authentication information 
            for any of our products or services etc. has been considered as a part of Personal Information
            </p>
            <br></br>
            <p>
            Zimba may collect, store, process and transfer your Personal Information well in 
            accordance with the applicable laws and regulations for a variety of regulatory and business purposes.
            </p>
            <br></br>
            <b>These may include, but are not limited to:</b>
            <StyledUL>
                <StyledLI>Verify your identity;</StyledLI>
                <StyledLI>Complete transactions effectively and bill for products and services;</StyledLI>
                <StyledLI>Respond to your request for service or assistance;</StyledLI>
                <StyledLI>Perform market analysis, market research, business and operational that
                 help us get valuable insights to enhance customer experience as well as improve upon service deliverys;
                 </StyledLI>
                <StyledLI>Provide, maintain, personalize and improve our products and services;</StyledLI>
                <StyledLI>Anticipate and resolve issues and concerns with our products and services;</StyledLI>
                <StyledLI>Promote and market products services and offers which we consider may be of interest to you and/or may benefit you;</StyledLI>
                <StyledLI>Ensure adherence to legal and regulatory requirements for prevention and detection of frauds and crimes;</StyledLI>
            </StyledUL>
            <br></br>
            <p>
            Non-personal information can be used to deliver based on your interest, 
            for analyzing different trends and determining the performance of these services
            </p>
            <br></br>
            <p>
            This Privacy Policy describes the Personal Information which we may collect and 
            provides our approach towards handling and 
            usage of or dealing with the same in compliance with the applicable laws and regulations
            </p>
            <br></br>
            <p>
            Please read the terms of this Privacy Policy carefully. By using and continuing to use our 
            products and services, you are deemed to have accepted and consented to the terms of this Policy
            </p>
            <br></br>
            <p>
            Zimba reserves the right to amend or modify this Privacy Policy at any time, as and when the need arises. 
            We request you to visit our website www.Zimba.in periodically for contemporary information and changes
            </p>
          </BoxContent>
        </Box>
        <Box id="box2">
          <div className='flex'><IconSection><MoveUpIcon style ={{fontSize: '30px'}}  /></IconSection><BoxTitle>Disclosure & Transfer of Personal Information</BoxTitle></div>
          <hr style={{ border: '0.5px solid #ddd', margin:'24px 0px' }} />
          <BoxContent>
            <p>
            Internal Use: As explained in the section “Collection of Personal Information” 
            Zimba may utilize some or all available Personal Information for internal assessments, 
            measures, operations and related activities or any other lawful purpose
            </p>
            <br></br>
            <p>
            We may share your Personal Information for the purposes detailed in this
            Privacy Policy with our internal third parties, which are the other entities in our group of companies.
            </p>
            <br></br>
            <p>
            We may transfer your personal information or other information collected, stored, processed by us to any other entity or 
            organization located in Uganda or outside Uganda only in order to 
            fulfil the terms of a contract we have with you or if you have consented (at the time of collection of information) to the same. 
            This may also include sharing of aggregated information with them in order for them to understand our environment and consequently, 
            provide you with better services. While sharing your Personal Information with third parties, adequate organizational, technical and security 
            measures shall be taken to ensure that reasonable security practices are followed at the third party.
            </p>
            <br></br>
            <p>
            If you wish to opt out of receiving product or service related communication from us, 
            please email us at <StyledLink href="mailto:admin@zimba.io">admin@zimba.io</StyledLink>
            </p>
            <br></br>
            <p>
            Government Agencies: We may also share your Personal Information without your consent with 
            Government agencies or other authorized law enforcement agencies (LEAs) mandated under law to 
            obtain such information for the purpose of verification of identity or for prevention, detection, 
            investigation including but not limited 
            to cyber incidents, prosecution, and punishment of offense
            </p>
            <br></br>
            <p>
            We may obtain your consent for sharing your Personal Information in several ways, 
            such as in writing, online, through "click-through" agreements; orally, 
            including through interactive voice response; or when your consent is part of the 
            terms and conditions pursuant to which we provide you service. We, however, assure you that 
            Zimba does not disclose your Personal Information to unaffiliated third parties (parties outside Zimba corporate network and its Strategic and Business Partners) 
            which could lead to invasion of your privacy.
            </p>
            <br></br>
            <p>
            Zimba may employ, contract or include third parties (as defined in section 1: Collection of Personal Information) 
            external to itself for strategic, business, tactical and operational purposes. Such agencies, though external to Zimba, 
            will always be entities which are covered by contractual agreements wherein we shall take reasonable measures 
            to ensure that the authorized third parties adopt
             reasonable level of security practices and procedures to ensure security of Personal Information
            </p>
            <br></br>
          </BoxContent>
        </Box>
        <Box id="box3">
          <div className='flex'><IconSection><DevicesIcon style ={{fontSize: '30px'}}  /></IconSection><BoxTitle>Collection of Personal Information</BoxTitle></div>
          <hr style={{ border: '0.5px solid #ddd', margin:'24px 0px' }} />
          <BoxContent>
            <p>
            We may collect your Personal Information when you use our services or otherwise interact with us during the course of our relationship. 
            The Personal Information may be collected by Zimba through the Customer Application Form (CAF) or through its authorized third parties. 
            Third Party is a service provider who associates with Zimba and is involved in handling, managing, storing, processing, protecting 
            and transmitting information on behalf of Zimba. 
            This definition also includes all sub-contractors, consultants and/or representatives of the Third party.
            </p>
            <br></br>
            <p>
            We may also collect and hold information related to your utilization of our services which may include your call details, 
            cell tower location information contact list, 
            phone numbers, device details, IMSI, purchasing habits, 
            IP Address your browsing history on our website or app, location details and additional information provided by you while using our services
            </p>
            <br></br>
            <p>
            We may keep a log of the activities performed by you on our network and websites by using various internet 
            techniques such as web cookies, web beacons, server log files, etc. for analytical purposes and for analysis of the amiability of various features on our site.
            This information may be used to provide you with a better experience at our portal along with evidentiary purposes. At any time while you are browsing our site, 
            if you do not wish to share browsing information, 
             you may opt out of receiving the cookies from our site by making appropriate changes to your browser privacy settings. 
            Please refer to Zimba’s Online Cookie Policy for more details.
            </p>
            <br></br>
            <p>
            When you communicate with us, we may monitor or record our interaction to help us serve you better, 
            to personalize your experiences, to train or otherwise support employees, resources or systems and for security purposes.
            </p>
            <br></br>
            <p>
            We may obtain Personal Information from third parties including but not limited to credit information, 
            demographic and interest data, contact, information from social media platforms when you interact with us via social media or 
            use your social media login to interact with Zimba sites or offers,
             marketing lead and other information we may receive.
            </p>
            <br></br>
            <p>
            In case you do not provide your information or consent for usage of Personal Information or later on withdraw your consent 
            for usage of the personal information so collected,
             Zimba reserves the right to discontinue the services for which the said information was sought.
            </p>
            <br></br>
          </BoxContent>
        </Box>
        <Box id="box4">
          <div className='flex'><IconSection><SecurityIcon style ={{fontSize: '30px'}}  /></IconSection><BoxTitle>Security Practices & Procedures</BoxTitle></div> 
          <hr style={{ border: '0.5px solid #ddd', margin:'24px 0px' }} />
          <BoxContent>
            <p>
            We adopt reasonable security practices and procedures, in line with international standard IS/ISO/IEC 27001 and applicable laws, to include,
             technical, operational, managerial and physical security 
            controls in order to protect your Personal Information from unauthorized access, or disclosure while it is under our control.
            </p>
            <br></br>
            <p>
            We further take adequate steps to ensure that our third parties adopt reasonable 
            levels of security practices and procedures to ensure security of Personal Information
            </p>
            <br></br>
            <p>
            While we observe reasonable security measures to protect your Personal Information on all our digital platforms and internet connections, 
            security risks may still arise for reasons outside of our control such as hacking, virus disseminations, 
            force majeure events, breaches of firewall and secure server software etc. 
            Please note that the above-mentioned measures do not guarantee absolute protection to the Personal Information
            </p>
            <br></br>
            <p>
            For any responsible disclosure of a security vulnerability in our website, mobile application or our services, please send an email to
             <br></br> <StyledLink href="mailto:security@zimba.io">security@zimba.io</StyledLink>
            </p>
            <br></br>
          </BoxContent>
        </Box>
        <Box id="box5">
          <div className='flex'><IconSection><CollectionsIcon style ={{fontSize: '30px'}} /></IconSection><BoxTitle>Access & Corrections</BoxTitle></div>
          <hr style={{ border: '0.5px solid #ddd', margin:'24px 0px' }} />
          <BoxContent>
            <p>
            To the extent feasible and subject to applicable laws we shall provide you access to the information that Zimba holds about you as well as 
            facilitate any correction and updation of information. Zimba may need to request specific information from you to help us confirm your identity and
             ensure your right to access your personal data (or to exercise other rights you may have under applicable law). 
             This is a security measure to ensure that personal data is not disclosed to any person who does not have a right to receive it. 
             We may also contact you to ask you for further information in relation to your request to speed up our response.
             We use best efforts to respond to legitimate requests within a reasonable timeframe.
            </p>
            <br></br>
            <p>
            If you no longer want to receive marketing messages from Zimba, you can choose to opt out at any time. If you’ve previously opted in to receive personalized content based on how and where you use our network, 
            you can also opt out at any time The easiest way to opt out is by sending an email to <StyledLink href="mailto:admin@zimba.io">admin@zimba.io</StyledLink>
            </p>
            <br></br>
            <b>There are also various other ways to opt out:</b>
            <StyledUL>
                <StyledLI>Call our customer services team at <StyledLink href="tel:+25676389580">+256 763895805</StyledLink> or  <StyledLink href="tel:+256708695675">+256 708695675</StyledLink>
                </StyledLI>
                <StyledLI>Disable push notification messages, including marketing messages,
                    at any time in our apps by changing the notification settings on your device or by uninstalling the app
                </StyledLI>
            </StyledUL>
            <br></br>
            <p>
            You may have received marketing from Zimba even if you’re not a customer or have never had contact with us. 
            This is a result of third-party marketing lists which Zimba may acquire from time to time, stating that you have given permission to be 
            contacted by other organizations. If you’ve registered with us to opt out of marketing from Zimba, you shouldn’t receive such communications. 
            We reserve the right to modify this Privacy Notice. We will post any changes to our Privacy Notice on this page. 
            Please check the “Last Updated” legend at the top of this page to see when this Privacy Notice was last revised. 
            We encourage you to check frequently to see the current Privacy Notice to be informed of how Zimba is committed to protecting your information and providing you with improved content on 
            our site in order to enhance your experience.
            </p>
          </BoxContent>
        </Box>
        
        <Box id="box6">
          <div className='flex'><IconSection><FeedbackIcon style ={{fontSize: '30px'}}  /></IconSection><BoxTitle>Feedback & Concerns</BoxTitle></div> 
          <hr style={{ border: '0.5px solid #ddd', margin:'24px 0px' }} />
          <BoxContent>
            <p>
            We are committed to safeguard your personal information collected and handled by us and look forward to your continued support for the same. 
            In case of any feedback or concern regarding protection of your personal information, you can contact us at <StyledLink href="mailto:privacy@zimba.io">privacy@zimba.io</StyledLink>
            </p>
          </BoxContent>
        </Box>
      </BoxContainer>   
    <TOC>
          <h1><HighlightSpan>Table of Content</HighlightSpan></h1>
          <br></br>
          <TOCItem onClick={() => handleScrollToSection('box1')} className={activeSection === 'box1' ? 'active' : ''}>Online Privacy Policy</TOCItem>
          <TOCItem onClick={() => handleScrollToSection('box2')} className={activeSection === 'box2' ? 'active' : ''}>Disclosure & Transfer of Personal Information</TOCItem>
          <TOCItem onClick={() => handleScrollToSection('box3')} className={activeSection === 'box3' ? 'active' : ''}>Collection of Personal Information</TOCItem>
          <TOCItem onClick={() => handleScrollToSection('box4')} className={activeSection === 'box4' ? 'active' : ''}>Security Practices & Procedures</TOCItem>
          <TOCItem onClick={() => handleScrollToSection('box5')} className={activeSection === 'box5' ? 'active' : ''}>Access & Corrections</TOCItem>
          <TOCItem onClick={() => handleScrollToSection('box6')} className={activeSection === 'box6' ? 'active' : ''}>Feedback & Concerns</TOCItem>
        </TOC>
     </InnerWrapper>
     {showBackToTop && ( // Conditionally render the button based on scroll position
          <BackToTopb onClick={handleScrollToTop}>
              <ArrowUpSVG />
          </BackToTopb>
                )}
        </Wrapper>
      </div>
    
  )
}



const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

const BoxContainer = styled.div`
  padding: 2px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
  align-items: flex-start;
  width: 70%;

   


  @media (max-width: 1160px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
  }
  @media (max-width: 885px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

`;

const Box = styled.div`
  width: 100%;
  padding: 20px;
  gap: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  color: #09263b;

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 884px) {
    width: auto;
  }
`;

const IconSection = styled.div`
  margin: 0;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1f5a2d;
`;

const BoxTitle = styled.h3`
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 0.83em;
  text-align: center;
  display: flex;
`;

const BoxContent = styled.p`
  font-size: 14px;
  margin-top: 10px;
  text-align: justify;
  line-height: 1.5;
  color: #09263b;
`;

const StyledUL = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 10px;

  @media (max-width: 768px) {
    padding-left: 10px;
  }
`;

const StyledLI = styled.li`
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const StyledLink = styled.a`
  color: green;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const TOC = styled.nav`
  position: sticky;
  top: 50px;
  right: 0;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
  width: 300px;

  @media (max-width: 960px) {
    position: static;
    margin-top: 5px;
    top: 50px;
    left: 10px;
    padding: 20px;
    background-color: #f7f7f7;
    margin-bottom: 20px;
    
  }
  @media (max-width: 768px) {
    position: static;
    margin-top: 5px;
    top: 50px;
    left: 10px;
    padding: 20px;
    background-color: #f7f7f7;
    margin-bottom: 20px;
    width:100% ;
  }

 
`;

const TOCItem = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    color: green;
  }
`;


const BackToTopb = styled.div`
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  position: fixed;
  bottom: 90px;
  right: 33px;
  background-color: #197B22;
  width:56px;
  height:56px;
  border-radius: 100%;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  z-index:999;
  
 

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;