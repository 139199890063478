import styled from "styled-components";
import { highlightColor, primaryColor } from "./Theme";
import { lighten } from "@mui/material";

export const Wrapper = styled.section`
  width: 100%;
`;

export const RowWrapper = styled.section`
  width: 100%;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

export const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

export const TitleP = styled.p`
  line-height: 1.3rem;
  max-width: 450px;
  @media (max-width: 960px) {
    // 
  }
`;

export const HeaderTitle = ({ children }) => (
  <h1
    style={{ maxWidth: 500 }}
    className="font30 extraBold"
    data-aos="fade-up"
    data-aos-duration="1500"
  >
    {children}
  </h1>
);

export const HighlightSpan = styled.span`
  background: linear-gradient(
    to right,
    ${lighten(highlightColor, 0.1)},
    ${primaryColor}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const GapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap + "px"};
`;
