import { ChevronRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function openLink(link) {
  window.open(link, "_blank");
}

export const HeaderButton = ({ children, onClick, link, type, to }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={
        onClick
          ? onClick
          : link
          ? () => {
              openLink(link);
            }
          : to
          ? () => navigate(to)
          : null
      }
      disableElevation
      size="large"
      type={type}
      variant="contained"
      endIcon={<ChevronRight />}
    >
      {children}
    </Button>
  );
};
