import React from "react";
import { Why } from "../sections/Why";
import { Features } from "../sections/Features";
import { Ezyfi } from "../sections/Ezyfi";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";

export const BankingPlatform = () => {
  return (
    <>
      <Ezyfi />
      <Features />
      <Why />
      <PatternStrip />
      <Footer />
    </>
  );
};
