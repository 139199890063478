import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollTop = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};
