// screens
import iDash from "./screens/sreen1.png";
import iLoans from "./screens/loans.png";
import iScreen1 from "./screens/filter.png";
import iScreen2 from "./screens/clients.png";
import iScreen3 from "./screens/repay.png";
import iScreen4 from "./screens/active.png";
import iScreen5 from "./screens/side.png";
import iScreen6 from "./screens/screen1.jpg";
import iScreen7 from "./screens/journal.png";
// illustrations
import iIlls1 from "./illustrations/illustration1.png";
import posimg from "./pos/photo.png";

import Amani from "./Institutions/Amani Logo.jpg";
import Duckhill from "./Institutions/Duck Hill.jpg";
import Khezza from "./Institutions/Khezza.jpg";
import Sleekers from "./Institutions/Sleekers.jpg";
import wazesha from "./Institutions/Wazesha Logo.jpg";

import fitspa from "./certifactes/fitspa 1.jpg";
import kcca from "./certifactes/KCCA 1.jpg";
import ursb from "./certifactes/ursb 1.jpg";
import nita from "./certifactes/nita2 1.jpg";
import kbis from "./certifactes/kampalabusiness 1.jpg";
import pdpo from "./certifactes/datap2 1.jpg";
import umra from "./certifactes/umra.png";
import wallet from "./wallet/walletts.png"
import manageSVG from "./illustrations/manage1.jpg"


export const DashImg = iDash;
export const LoansImg = iLoans;
export const Screen1 = iScreen1;
export const Screen2 = iScreen2;
export const Screen3 = iScreen3;
export const Screen4 = iScreen4;
export const Screen5 = iScreen5;
export const Screen6 = iScreen6;
export const Screen7 = iScreen7;

export const Ills1 = iIlls1;
export const Posimg = posimg;
export const Amaniimg = Amani;
export const Duckhillimg = Duckhill;
export const Khezzaimg = Khezza;
export const Sleekersimg = Sleekers;
export const Wazeshaimg = wazesha;

export const Fitspaimg = fitspa;
export const Kccaimg = kcca;
export const URSBimg = ursb;
export const Nitaimg = nita;
export const Kbisimg = kbis;
export const Pdpoimg = pdpo;
export const Umraimg = umra;
export const Walletimg = wallet;

export const manageimg = manageSVG;

