import { Menu } from "antd";
import { Link as LinkDOM, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { getLocation } from "../../utils/Utils";

const { SubMenu, Item } = Menu;

export function RecursiveMenu(data, onClick) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (getLocation() !== "/") {
      navigate("/");
    }
    onClick();
  };

  return data.map(({ label, to, children = [] }) => {
    if (!children?.length) {
      return (
        <Item key={Math.random()}>
          {to ? (
            <LinkDOM className="whiteColor" to={to}>
              {label}
            </LinkDOM>
          ) : (
            <Link
              className="whiteColor"
              to={label.toLowerCase()}
              onClick={handleClick}
              smooth={true}
            >
              {label}
            </Link>
          )}
        </Item>
      );
    }

    return (
      <SubMenu key={Math.random()} title={label}>
        {RecursiveMenu(children)}
      </SubMenu>
    );
  });
}
