import React from "react";
import styled from "styled-components";
import { teamMembers } from "../constants/siteData";
import { TeamBox } from "../components/team/TeamBox";
import {
  HeaderTitle,
  HighlightSpan,
  TitleP,
} from "../components/styled/Styled";

export const Team = () => {
  return (
    <Wrapper id="team">
      <BackgroundWrapper className="poka-pattern" />
      <div className="container textCenter flexCenter flexColumn gap20">
        <HeaderTitle>
          The <HighlightSpan>Team</HighlightSpan>
        </HeaderTitle>

        <TitleP
          className="font14"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1500"
        >
          Zimba consists of <b>experienced professionals</b> with various
          complimentary backgrounds who are here to provide you with the best
          possible experience!
        </TitleP>

        <br />

        <TeamGridWrapper>
          {teamMembers.map((val, i) => (
            <div
              key={i}
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay={i * 100}
            >
              <TeamBox data={val} />
            </div>
          ))}
        </TeamGridWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  padding: 80px 0;
  min-height: 50vh;
  @media (max-width: 960px) {
    padding: 40px 0;
    flex-direction: column;
  }
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  // opacity: 0.3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TeamGridWrapper = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 360px) {
    grid-template-columns: 1fr;
  }
`;
