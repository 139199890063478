import { FormatQuote } from "@mui/icons-material";
import { Avatar, Divider } from "@mui/material";
import styled from "styled-components";

export default function TestimonialBox({ data }) {
  const { text, name, occupation, image } = data || {};
  return (
    <Wrapper className="radius8 font13">
      <div className="">
        <FormatQuote sx={{ fontSize: 80 }} />
      </div>
      <p>{text}</p>

      <Divider sx={{ my: 2 }} />

      <div className="flexNullCenter gap20">
        <Avatar src={image} sx={{ width: 50, height: 50 }} />
        <div>
          <p className="semiBold">{name}</p>
          <p className="mainColor font11">{occupation}</p>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 10px 40px 30px 40px;
  margin-block: 50px 100px;
  box-shadow: rgba(0, 0, 0, 0.13) -60px 40px 80px;
  @media (max-width: 860px) {
    margin-block: 50px 80px;
    padding: 5px 30px 15px #0px;
  }
`;
