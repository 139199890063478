import iIsaac from "./isaacwere.jpg";
import iDoreen from "./Doreen-Kansiime.jpg";
import iBrian from "./Brian-Ssemate.jpg";
import iElly from "./Elly-Yiga.jpg";
import iJuliet from "./julieimg1.jpg";
import iOshin from "./oshin.jpg";
import iShamilah from "./shamirah.jpg";
import iSam from "./Sam-Ellis.jpg";
import iAnna from "./annaimg1.jpg";
import iStella from "./stellaimg1.jpg";
import iJoy from "./joyimg1.jpg";
import iMariam from "./mariamimg1.jpg";
import iMoses from "./mosesimg1.jpg";
import iDorothy from "./Dorothy.jpeg";
import iCalvin from "./calvin kirabo.jpg";

export const IsaacImg = iIsaac;
export const DoreenImg = iDoreen;
export const BrianImg = iBrian;
export const EllyImg = iElly;
export const JulietImg = iJuliet;
export const OshinImg = iOshin;
export const SamImg = iSam;
export const ShamilahImg = iShamilah;

export const JoyImg = iJoy;
export const MariamImg = iMariam;
export const StellaImg = iStella;
export const AnnaImg = iAnna;
export const MosesImg = iMoses;
export const DorothyImg = iDorothy;
export const CalvinImg = iCalvin;
