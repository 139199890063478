import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as LinkDOM, useNavigate } from "react-router-dom";

// Assets
import Sidebar from "../sidebar/Sidebar";
import { MenuRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { contactLink, navLinks } from "../../constants/links";
import Backdrop from "../elements/Backdrop";
import { LogoDark } from "../../assets/svg/Svgs";
import { getLocation } from "../../utils/Utils";
import { Dropdown } from "../dropdown/Dropdown";

export const navStyle = { padding: "10px 15px" };

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const handleClick = () => {
    if (getLocation() !== "/") {
      navigate("/");
    }
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate"
        style={
          y > 100
            ? {
                height: "60px",
              }
            : { height: "80px" }
        }
      >
        <NavInner className="container flexSpaceCenter">
          <Link
            className="pointer flexNullCenter"
            to="home"
            duration={2000}
            smooth={true}
          >
            <LogoDark height={40} />
          </Link>

          <UlWrapper className="flexNullCenter">
            {navLinks.map((val, i) => {
              const { label, offset, children, to } = val;
              return (
                <li className="semiBold font14 pointer" key={i}>
                  {children ? (
                    <Dropdown label={label} list={children} />
                  ) : to ? (
                    <LinkDOM to={to} style={navStyle}>
                      {label}
                    </LinkDOM>
                  ) : (
                    <Link
                      className="animate"
                      activeClass="active-link"
                      style={navStyle}
                      to={label.toLowerCase()}
                      spy={true}
                      smooth={true}
                      offset={offset}
                      onClick={handleClick}
                    >
                      {label}
                    </Link>
                  )}
                </li>
              );
            })}
          </UlWrapper>

          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font14 pointer">
              {/* <LinkDOM to={contactLink} style={{ padding: "10px 30px 10px 0" }}>
                Sign Up
              </LinkDOM> */}
              {/* <a
                target="_blank"
                rel="noopener noreferrer"
                href="/docs"
                style={{ padding: "10px 30px 10px 0" }}
              >
                Sign Up
              </a> */}
            </li>
            <Button
              className="lightLink"
              variant="contained"
              disableElevation
              href={contactLink}
            >
              <p className="gilroy">Contact Us</p>
            </Button>
            {/* <Button
              className="lightLink"
              variant="contained"
              disableElevation
              href="https://app.zimba.io/client/login"
            >
              Sign In
            </Button> */}
          </UlWrapperRight>

          <MenuWrapper className="borderBox radius6">
            <IconButton onClick={() => toggleSidebar(!sidebarOpen)}>
              <MenuRounded />
            </IconButton>
          </MenuWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 923px) {
    display: none;
  }

`;
const UlWrapperRight = styled.ul`
  @media (max-width: 923px) {
    display: none;
  }
  
`;

const MenuWrapper = styled.div`
  display: none;
  @media (max-width: 923px) {
    display: flex;
  }
`;
